/* eslint-disable */

import { NovaRTKApi as api } from '../store/novaRTKQApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    postAccessAdminVoidAccessCodes: build.mutation<
      PostAccessAdminVoidAccessCodesApiResponse,
      PostAccessAdminVoidAccessCodesApiArg
    >({
      query: queryArg => ({
        url: `/access/admin/void-access-codes`,
        method: 'POST',
        body: queryArg.batchVoidAccessCodes,
      }),
    }),
    postAccessCompatibilityAssignLock: build.mutation<
      PostAccessCompatibilityAssignLockApiResponse,
      PostAccessCompatibilityAssignLockApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/assign-lock`,
        method: 'POST',
        body: queryArg.assignLock,
      }),
    }),
    postAccessCompatibilityAddSeamAccount: build.mutation<
      PostAccessCompatibilityAddSeamAccountApiResponse,
      PostAccessCompatibilityAddSeamAccountApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/add-seam-account`,
        method: 'POST',
        body: queryArg.importAdditionalSeamAccount,
      }),
    }),
    postAccessCompatibilityOnboardSeamAccount: build.mutation<
      PostAccessCompatibilityOnboardSeamAccountApiResponse,
      PostAccessCompatibilityOnboardSeamAccountApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/onboard-seam-account`,
        method: 'POST',
        body: queryArg.importSeamAccount,
      }),
    }),
    postAccessCompatibilityLegacyCompanyIdSyncLocks: build.mutation<
      PostAccessCompatibilityLegacyCompanyIdSyncLocksApiResponse,
      PostAccessCompatibilityLegacyCompanyIdSyncLocksApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/${queryArg.legacyCompanyId}/sync-locks`,
        method: 'POST',
      }),
    }),
    postAccessCompatibilityUnassignLock: build.mutation<
      PostAccessCompatibilityUnassignLockApiResponse,
      PostAccessCompatibilityUnassignLockApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/unassign-lock`,
        method: 'POST',
        body: queryArg.unassignLock,
      }),
    }),
    postAccessCompatibilityOnboardingGenerateLink: build.mutation<
      PostAccessCompatibilityOnboardingGenerateLinkApiResponse,
      PostAccessCompatibilityOnboardingGenerateLinkApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/onboarding/generate-link`,
        method: 'POST',
        params: { redirectUrl: queryArg.redirectUrl },
      }),
    }),
    getAccessCompatibilityOnboardingByLegacyCompanyIdLocks: build.query<
      GetAccessCompatibilityOnboardingByLegacyCompanyIdLocksApiResponse,
      GetAccessCompatibilityOnboardingByLegacyCompanyIdLocksApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/onboarding/${queryArg.legacyCompanyId}/locks`,
        params: { skip: queryArg.skip, take: queryArg.take, keyword: queryArg.keyword },
      }),
    }),
    getAccessCompatibilityOnboardingByLegacyCompanyIdUnits: build.query<
      GetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsApiResponse,
      GetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/onboarding/${queryArg.legacyCompanyId}/units`,
        params: { skip: queryArg.skip, take: queryArg.take, keyword: queryArg.keyword },
      }),
    }),
    getAccessCompatibilityOnboardingByLegacyCompanyIdGroups: build.query<
      GetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsApiResponse,
      GetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/onboarding/${queryArg.legacyCompanyId}/groups`,
      }),
    }),
    getAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatus: build.query<
      GetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusApiResponse,
      GetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/onboarding/${queryArg.legacyCompanyId}/seam-onboarding-status`,
      }),
    }),
    getAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountId:
      build.query<
        GetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdApiResponse,
        GetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdApiArg
      >({
        query: queryArg => ({
          url: `/access/compatibility/onboarding/${queryArg.legacyCompanyId}/connect-webview/${queryArg.connectWebviewId}/connected-account-id`,
        }),
      }),
    $get: build.query<$getApiResponse, $getApiArg>({
      query: () => ({ url: `/` }),
    }),
    postAccessCompatibilityLocksLegacyLockIdLock: build.mutation<
      PostAccessCompatibilityLocksLegacyLockIdLockApiResponse,
      PostAccessCompatibilityLocksLegacyLockIdLockApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/locks/${queryArg.legacyLockId}/lock`,
        method: 'POST',
      }),
    }),
    postAccessCompatibilityLocksLegacyLockIdUnlock: build.mutation<
      PostAccessCompatibilityLocksLegacyLockIdUnlockApiResponse,
      PostAccessCompatibilityLocksLegacyLockIdUnlockApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/locks/${queryArg.legacyLockId}/unlock`,
        method: 'POST',
      }),
    }),
    postAccessCompatibilityMigrateLockAccess: build.mutation<
      PostAccessCompatibilityMigrateLockAccessApiResponse,
      PostAccessCompatibilityMigrateLockAccessApiArg
    >({
      query: queryArg => ({
        url: `/access/compatibility/migrate-lock-access`,
        method: 'POST',
        body: queryArg.batchMigrateLockAccess,
      }),
    }),
    postAccessSeamWebhookAccessCode: build.mutation<
      PostAccessSeamWebhookAccessCodeApiResponse,
      PostAccessSeamWebhookAccessCodeApiArg
    >({
      query: () => ({ url: `/access/seam/webhook/access-code`, method: 'POST' }),
    }),
    postAccessSeamWebhookDevice: build.mutation<
      PostAccessSeamWebhookDeviceApiResponse,
      PostAccessSeamWebhookDeviceApiArg
    >({
      query: () => ({ url: `/access/seam/webhook/device`, method: 'POST' }),
    }),
    postAccessSeamWebhookLock: build.mutation<
      PostAccessSeamWebhookLockApiResponse,
      PostAccessSeamWebhookLockApiArg
    >({
      query: () => ({ url: `/access/seam/webhook/lock`, method: 'POST' }),
    }),
    postOnboardingMigrateLegacyCompanyToNova: build.mutation<
      PostOnboardingMigrateLegacyCompanyToNovaApiResponse,
      PostOnboardingMigrateLegacyCompanyToNovaApiArg
    >({
      query: queryArg => ({
        url: `/onboarding/migrate-legacy-company-to-nova`,
        method: 'POST',
        body: queryArg.importLegacyCompany,
      }),
    }),
    getV1Reservations: build.query<GetV1ReservationsApiResponse, GetV1ReservationsApiArg>({
      query: queryArg => ({
        url: `/v1/reservations`,
        params: {
          legacy_company_id: queryArg.legacyCompanyId,
          legacy_unit_id: queryArg.legacyUnitId,
          checkin_after_utc: queryArg.checkinAfterUtc,
          skip: queryArg.skip,
          take: queryArg.take,
        },
      }),
    }),
    getV1ReservationsById: build.query<
      GetV1ReservationsByIdApiResponse,
      GetV1ReservationsByIdApiArg
    >({
      query: queryArg => ({ url: `/v1/reservations/${queryArg.id}` }),
    }),
    getPiiRecord: build.query<GetPiiRecordApiResponse, GetPiiRecordApiArg>({
      query: queryArg => ({ url: `/v1/reservation/pii/${queryArg.id}` }),
    }),
    savePiiRecord: build.mutation<SavePiiRecordApiResponse, SavePiiRecordApiArg>({
      query: queryArg => ({
        url: `/v1/reservation/pii/${queryArg.id}`,
        method: 'POST',
        body: queryArg.piiData,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as novaApi };
export type PostAccessAdminVoidAccessCodesApiResponse =
  /** status 200 OK */
  IResult | /** status 202 Accepted */ string;
export type PostAccessAdminVoidAccessCodesApiArg = {
  batchVoidAccessCodes: BatchVoidAccessCodes;
};
export type PostAccessCompatibilityAssignLockApiResponse = /** status 200 OK */ IResult;
export type PostAccessCompatibilityAssignLockApiArg = {
  assignLock: AssignLock;
};
export type PostAccessCompatibilityAddSeamAccountApiResponse =
  /** status 201 Created */ ImportSeamAccountResponse;
export type PostAccessCompatibilityAddSeamAccountApiArg = {
  importAdditionalSeamAccount: ImportAdditionalSeamAccount;
};
export type PostAccessCompatibilityOnboardSeamAccountApiResponse =
  /** status 201 Created */ ImportSeamAccountResponse;
export type PostAccessCompatibilityOnboardSeamAccountApiArg = {
  importSeamAccount: ImportSeamAccount;
};
export type PostAccessCompatibilityLegacyCompanyIdSyncLocksApiResponse =
  /** status 202 Accepted */ AcceptResponse;
export type PostAccessCompatibilityLegacyCompanyIdSyncLocksApiArg = {
  legacyCompanyId: number;
};
export type PostAccessCompatibilityUnassignLockApiResponse = /** status 200 OK */ IResult;
export type PostAccessCompatibilityUnassignLockApiArg = {
  unassignLock: UnassignLock;
};
export type PostAccessCompatibilityOnboardingGenerateLinkApiResponse =
  /** status 200 OK */ GenerateLinkResponse;
export type PostAccessCompatibilityOnboardingGenerateLinkApiArg = {
  redirectUrl?: string;
};
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdLocksApiResponse =
  /** status 200 OK */ DeviceLockViewModelPagedResult;
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdLocksApiArg = {
  legacyCompanyId: number;
  skip?: number;
  take?: number;
  keyword?: string;
};
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsApiResponse =
  /** status 200 OK */ UnitViewModelPagedResult;
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsApiArg = {
  legacyCompanyId: number;
  skip?: number;
  take?: number;
  keyword?: string;
};
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsApiResponse =
  /** status 200 OK */ GroupListViewModelPagedResult;
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsApiArg = {
  legacyCompanyId: number;
};
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusApiResponse =
  /** status 200 OK */ OnboardingStatusViewModel;
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusApiArg = {
  legacyCompanyId: number;
};
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdApiResponse =
  /** status 200 OK */ ConnectedAccountViewModel;
export type GetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdApiArg =
  {
    legacyCompanyId: number;
    connectWebviewId: string;
  };
export type $getApiResponse = unknown;
export type $getApiArg = void;
export type PostAccessCompatibilityLocksLegacyLockIdLockApiResponse = /** status 200 OK */ IResult;
export type PostAccessCompatibilityLocksLegacyLockIdLockApiArg = {
  legacyLockId: number;
};
export type PostAccessCompatibilityLocksLegacyLockIdUnlockApiResponse =
  /** status 200 OK */ IResult;
export type PostAccessCompatibilityLocksLegacyLockIdUnlockApiArg = {
  legacyLockId: number;
};
export type PostAccessCompatibilityMigrateLockAccessApiResponse = /** status 200 OK */ IResult;
export type PostAccessCompatibilityMigrateLockAccessApiArg = {
  batchMigrateLockAccess: BatchMigrateLockAccess;
};
export type PostAccessSeamWebhookAccessCodeApiResponse = /** status 200 OK */ IResult;
export type PostAccessSeamWebhookAccessCodeApiArg = void;
export type PostAccessSeamWebhookDeviceApiResponse = /** status 200 OK */ IResult;
export type PostAccessSeamWebhookDeviceApiArg = void;
export type PostAccessSeamWebhookLockApiResponse = /** status 200 OK */ IResult;
export type PostAccessSeamWebhookLockApiArg = void;
export type PostOnboardingMigrateLegacyCompanyToNovaApiResponse =
  /** status 201 Created */ ImportLegacyCompanyResponse;
export type PostOnboardingMigrateLegacyCompanyToNovaApiArg = {
  importLegacyCompany: ImportLegacyCompany;
};
export type GetV1ReservationsApiResponse = /** status 200 OK */ ReservationDtoPagedResult;
export type GetV1ReservationsApiArg = {
  legacyCompanyId: number;
  legacyUnitId?: number;
  checkinAfterUtc?: string;
  skip?: number;
  take?: number;
};
export type GetV1ReservationsByIdApiResponse = /** status 200 OK */ ReservationDto;
export type GetV1ReservationsByIdApiArg = {
  id: string;
};
export type GetPiiRecordApiResponse = /** status 200 OK */ PiiDto;
export type GetPiiRecordApiArg = {
  id: string;
};
export type SavePiiRecordApiResponse = unknown;
export type SavePiiRecordApiArg = {
  id: string;
  piiData: PiiData;
};
export type IResult = object;
export type BatchVoidAccessCodes = {
  accessCodeIds?: string[] | null;
};
export type AssignLock = {
  legacyLockId?: number;
  legacyPropertyId?: number;
};
export type ImportSeamAccountResponse = {
  url?: string | null;
  id?: string | null;
};
export type ImportAdditionalSeamAccount = {
  legacyCompanyId?: number;
  seamConnectedAccountId?: string | null;
};
export type ImportSeamAccount = {
  legacyCompanyId?: number;
  seamConnectedAccountId?: string | null;
};
export type AcceptResponse = {
  url?: string | null;
};
export type UnassignLock = {
  legacyLockId?: number;
};
export type GenerateLinkResponse = {
  connect_view_url: string | null;
};
export type DeviceLockViewModel = {
  legacy_company_id?: number;
  provider_account_id?: string | null;
  provider_account_user_email?: string | null;
  provider_account_name?: string | null;
  provider_image_url?: string | null;
  lock_legacy_id?: number | null;
  lock_friendly_name?: string | null;
  lock_image_url?: string | null;
  unit_id?: number | null;
  unit_name?: string | null;
  is_online?: boolean;
  last_synced_at?: string | null;
};
export type DeviceLockViewModelPagedResult = {
  data: DeviceLockViewModel[] | null;
  skip?: number;
  take?: number;
  total_count?: number;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type UnitViewModel = {
  company_id?: number;
  unit_id?: number;
  unit_name: string | null;
  is_lock_assigned?: boolean;
};
export type UnitViewModelPagedResult = {
  data: UnitViewModel[] | null;
  skip?: number;
  take?: number;
  total_count?: number;
};
export type GroupViewModel = {
  id: number;
  name: string | null;
};
export type GroupListViewModel = {
  groups: GroupViewModel[] | null;
};
export type GroupListViewModelPagedResult = {
  data: GroupListViewModel[] | null;
  skip?: number;
  take?: number;
  total_count?: number;
};
export type OnboardingStatusViewModel = {
  is_onboarded?: boolean;
};
export type ConnectedAccountViewModel = {
  connected_account_id: string | null;
  provider_account_user_email?: string | null;
};
export type MigrateLockAccess = {
  locksmithManagedLegacyLockId?: number;
  novaManagedLegacyLockId?: number;
};
export type BatchMigrateLockAccess = {
  lockPairs?: MigrateLockAccess[] | null;
};
export type ImportLegacyCompanyResponse = {
  url?: string | null;
  id?: string | null;
};
export type ImportLegacyCompany = {
  companyId?: number;
};
export type ReservationDto = {
  id: string | null;
  company_id: string | null;
  unit_id: string | null;
  status: string | null;
  checkin_local_date: string;
  checkin_local_time: string;
  checkout_local_date: string;
  checkout_local_time: string;
  pii_id: string | null;
  guest_count: number | null;
  access_code?: string | null;
  locale?: string | null;
  legacy_id?: number;
  legacy_company_id?: number;
  legacy_unit_id?: number;
  unit_name: string | null;
  unit_address: string | null;
  unit_timezone: string | null;
  external_id: string | null;
  external_listing_id: string | null;
  source: string | null;
  pms_status: string | null;
  pms_name: string | null;
  pms_created_at?: string;
  pms_updated_at?: string;
  external_guest_id: string | null;
  your_key_code?: string | null;
  is_test?: boolean | null;
};
export type ReservationDtoPagedResult = {
  data: ReservationDto[] | null;
  skip?: number;
  take?: number;
  total_count?: number;
};
export type PiiDto = {
  name?: string | null;
  phone?: string | null;
  email?: string | null;
};
export type PiiData = {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
};
export type PiiDataRead = {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  isEmpty?: boolean;
};
export const {
  usePostAccessAdminVoidAccessCodesMutation,
  usePostAccessCompatibilityAssignLockMutation,
  usePostAccessCompatibilityAddSeamAccountMutation,
  usePostAccessCompatibilityOnboardSeamAccountMutation,
  usePostAccessCompatibilityLegacyCompanyIdSyncLocksMutation,
  usePostAccessCompatibilityUnassignLockMutation,
  usePostAccessCompatibilityOnboardingGenerateLinkMutation,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdGroupsQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdQuery,
  use$getQuery,
  usePostAccessCompatibilityLocksLegacyLockIdLockMutation,
  usePostAccessCompatibilityLocksLegacyLockIdUnlockMutation,
  usePostAccessCompatibilityMigrateLockAccessMutation,
  usePostAccessSeamWebhookAccessCodeMutation,
  usePostAccessSeamWebhookDeviceMutation,
  usePostAccessSeamWebhookLockMutation,
  usePostOnboardingMigrateLegacyCompanyToNovaMutation,
  useGetV1ReservationsQuery,
  useGetV1ReservationsByIdQuery,
  useGetPiiRecordQuery,
  useSavePiiRecordMutation,
} = injectedRtkApi;
