import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import { Order } from '../Orders/OrdersTable';

export interface DeclineTransactionModalProps {
  order: Order;
  open: boolean;
  setOpen: (value: boolean) => void;
  onDecline: (reason: string) => void;
}

export const DeclineTransactionModal = ({
  order,
  open,
  setOpen,
  onDecline,
}: DeclineTransactionModalProps) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const handleDecline = async () => {
    if (reason.trim() === '') {
      setError(true);
    } else {
      onDecline(reason);
      setOpen(false);
    }
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
    if (e.target.value.trim() !== '') {
      setError(false);
    }
  };

  return (
    <>
      <Dialog
        maxWidth={false}
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: '900px',
            minHeight: '388px',
          },
        }}
      >
        <DialogTitle
          sx={{
            maxWidth: '900px',
            height: '64px',
            padding: '16px 24px',
            '& .MuiTypography-root': {
              fontFamily: 'Inter',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '32px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            },
          }}
        >
          {t('decline_order')}
        </DialogTitle>
        <DialogContent
          sx={{
            maxWidth: '900px',
            minHeight: '264px',
            padding: '8px 24px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.15px',
              padding: '0 0 20px 0',
            }}
          >
            {t('this_will_decline_order')} &apos;{order?.name ?? 'N/A'}&apos;.
          </Typography>
          <TextField
            id='outlined-multiline-static'
            label={t('reason_message')}
            placeholder={t('decline_order_placeholder')}
            multiline
            minRows={6}
            value={reason}
            onChange={handleReasonChange}
            inputProps={{
              fontFamily: 'Inter',
              weight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              letter: '0.15px',
              maxLength: 1000,
            }}
            error={error}
            helperText={error ? t('reason_message_is_required') : ''}
            sx={{
              margin: 0,
            }}
          />

          <Grid container justifyContent='flex-end'>
            <Typography variant='caption' color='textSecondary'>
              {reason.length}/1,000
            </Typography>
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            gap: '8px',
            padding: '8px 16px 16px 16px',
          }}
        >
          <Button onClick={() => setOpen(false)} color='primary'>
            {t('cancel')}
          </Button>
          <Button onClick={handleDecline} color='primary' variant='contained'>
            {t('decline')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeclineTransactionModal;
