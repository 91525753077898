import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useMedia } from '@operto/ui';
import * as React from 'react';

export interface LocksAssignPropertyDropDownSearchProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  value: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
}

const LocksAssignPropertyDropDownSearch = ({
  value,
  onChange,
  size = 'medium',
  fullWidth = false,
}: LocksAssignPropertyDropDownSearchProps) => {
  const { isMobile } = useMedia();

  const handleClearInput = () => {
    onChange({ currentTarget: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <FormControl
      variant='outlined'
      fullWidth={fullWidth}
      size={size}
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        margin: isMobile ? '0 8px 0 0' : undefined,
      }}
      data-testid='search-field'
    >
      <OutlinedInput
        inputProps={{ 'data-testid': 'search-field-input' }}
        value={value}
        onChange={onChange}
        placeholder='Search unit'
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position='end'>
            {value ? (
              <ClearIcon
                onClick={handleClearInput}
                sx={{ cursor: 'pointer' }}
                data-testid='search-field-clear-icon'
              />
            ) : (
              <TuneIcon />
            )}
          </InputAdornment>
        }
        sx={{
          borderRadius: '25px',
          '& .MuiOutlinedInput-input': {
            padding: isMobile ? '12px 14px' : undefined,
          },
        }}
      />
    </FormControl>
  );
};

export default LocksAssignPropertyDropDownSearch;
