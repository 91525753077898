import {
  CloseOutlined,
  DeleteOutlineOutlined,
  ModeEditOutlined,
  SellOutlined,
} from '@mui/icons-material';
import {
  TitlebarActionIcon,
  TitlebarActionIconContainer,
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarButton,
  TitlebarRoot,
  TitlebarSwitch,
  TitlebarTitle,
  TitlebarTitleButton,
  TitlebarTitleIcon,
} from '@operto/ui';
import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function UpsellPageTitlebar({
  title,
  isActive,
  onActiveChange,
  onTitleChange,
  onSaveClick,
  onDeleteClick,
  saveDisabled,
  helperText,
}: UpsellPageTitlebarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [editMode, setEditMode] = React.useState(false);
  const [prevTitle, setPrevTitle] = React.useState(title);

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('offers'),
      href: '/offers',
    },
  ];

  const handlerSaveClick = () => {
    onSaveClick();
    setEditMode(false);
  };

  const handleDeleteClick = () => {
    onDeleteClick();
    setEditMode(false);
  };

  return (
    <TitlebarRoot>
      <TitlebarTitleIcon>
        <SellOutlined />
      </TitlebarTitleIcon>

      <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)}>
        <TitlebarTitle
          title={title}
          editMode={editMode}
          helperText={helperText}
          onChange={e =>
            onTitleChange(
              (e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>).target.value,
            )
          }
          data-testid='edit-offer-header'
          textFieldProps={{
            placeholder: t('offer_upsell_title'),
          }}
        >
          {editMode ? (
            <TitlebarTitleButton
              onClick={() => {
                onTitleChange(prevTitle);
                setEditMode(false);
              }}
            >
              <CloseOutlined />
            </TitlebarTitleButton>
          ) : (
            <TitlebarTitleButton
              onClick={() => {
                setEditMode(true);
                setPrevTitle(title);
              }}
            >
              <ModeEditOutlined />
            </TitlebarTitleButton>
          )}
        </TitlebarTitle>
      </TitlebarBreadCrumb>

      <TitlebarActionIcon tooltip={t('delete')} onClick={handleDeleteClick}>
        <DeleteOutlineOutlined />
      </TitlebarActionIcon>

      <TitlebarActionIconContainer>
        <TitlebarSwitch
          tooltip={t(isActive ? 'active' : 'inactive')}
          checked={isActive}
          onChange={e => onActiveChange((e as React.ChangeEvent<HTMLInputElement>).target.checked)}
        />
      </TitlebarActionIconContainer>

      <TitlebarButton onClick={handlerSaveClick} disabled={saveDisabled}>
        {t('save')}
      </TitlebarButton>
    </TitlebarRoot>
  );
}

type UpsellPageTitlebarProps = {
  title: string;
  isActive: boolean;
  onActiveChange: (isActive: boolean) => void;
  onTitleChange: (title: string) => void;
  onSaveClick: () => void;
  onDeleteClick: () => void;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  helperText?: string;
};
