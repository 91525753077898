import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, Button, CircularProgress } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@operto/ui';
import { companySelector } from 'company/state/companySelectors';
import { formatRelativeToTimeZone } from 'helper/date';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import { LockFilterType } from 'lock/lockType';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import {
  DeviceLockViewModel,
  UnitViewModel,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsQuery,
  usePostAccessCompatibilityAssignLockMutation,
  usePostAccessCompatibilityUnassignLockMutation,
} from 'services/novaApi';
import { PaginatedTable } from 'ui-library/Components/table/PaginatedTable';
import { TableCell } from 'ui-library/Components/table/TableCell';
import LockAssignPropertyDropdown from './LockAssignPropertyDropdown';
import { LocksTitleBar } from './LocksTitleBar';
import { UnassignUnitDialog } from './UnassignUnitDialog';

export interface LocksTableProps {
  filterType: LockFilterType;
  menu?: React.ReactNode;
}

interface PagedResult<T> {
  data: T[];
  skip: number;
  take: number;
  total_count: number;
}

const INITIAL_LOCKS_STATE: PagedResult<DeviceLockViewModel> = {
  data: [],
  skip: 0,
  take: 100,
  total_count: 0,
};

const INITIAL_UNITS_STATE: PagedResult<UnitViewModel> = {
  data: [],
  skip: 0,
  take: 10,
  total_count: 0,
};

const LocksTable = ({ menu }: LocksTableProps) => {
  const company = useAppSelector(companySelector());
  const companyId = company?.id;

  // Pagination state for locks
  const [locksPagination, setLocksPagination] = useState({
    pageNum: INITIAL_LOCKS_STATE.skip,
    numPerPage: INITIAL_LOCKS_STATE.take,
    keyword: '',
  });

  // Pagination state for units
  const [unitsPagination, setUnitsPagination] = useState({
    pageNum: INITIAL_UNITS_STATE.skip,
    numPerPage: INITIAL_UNITS_STATE.take,
    keyword: '',
  });

  const [allUnits, setAllUnits] = useState<UnitViewModel[]>([]); // Store all loaded units
  const [locksData, setLocksData] = useState<DeviceLockViewModel[]>([]); // Store locks data locally

  const { t } = useTranslation();
  const { snackbar } = useSnackbar();

  const [assignLock] = usePostAccessCompatibilityAssignLockMutation();
  const [unassignLock] = usePostAccessCompatibilityUnassignLockMutation();

  const [assignLockTimeout, setAssignLockTimeout] = useState<NodeJS.Timeout | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [locksBeingUnassigned, setLocksBeingUnassigned] = useState<number[]>([]);

  // Fetching locks
  const {
    data: locks = INITIAL_LOCKS_STATE,
    isLoading: isLocksLoading,
    isFetching: isLocksFetching,
    refetch,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdLocksQuery({
    legacyCompanyId: companyId,
    skip: locksPagination.pageNum,
    take: locksPagination.numPerPage,
    keyword: locksPagination.keyword,
  });

  // Fetching units with pagination
  const { data: units = INITIAL_UNITS_STATE, isLoading: isUnitsLoading } =
    useGetAccessCompatibilityOnboardingByLegacyCompanyIdUnitsQuery({
      legacyCompanyId: companyId,
      skip: unitsPagination.pageNum,
      take: unitsPagination.numPerPage,
    });

  // Infinite scroll: Load more units when user scrolls to bottom
  const loadMoreUnits = () => {
    if (!isUnitsLoading && units.total_count > allUnits.length) {
      setUnitsPagination(prev => ({
        ...prev,
        pageNum: prev.pageNum + prev.numPerPage, // Increment page number
      }));
    }
  };

  const handleLocksFetch = useCallback(
    (pageNum: number, numPerPage: number, searchValue?: string) => {
      setLocksPagination({ pageNum, numPerPage, keyword: searchValue || '' });
    },
    [],
  );

  useEffect(() => {
    if (locks.data) {
      setLocksData(locks.data);
    }
  }, [locks]);

  const handleAssignLock = (lockId: number, unitId: number, unitName: string) => {
    // Update the UI immediately
    setLocksData(prevLocks =>
      prevLocks.map(lock =>
        lock.lock_legacy_id === lockId ? { ...lock, unit_id: unitId, unit_name: unitName } : lock,
      ),
    );
    snackbar(`Unit Assigned to '${unitName}'`, undoAction(lockId, unitId));

    // Disable the assigned unit in the units list
    setAllUnits(prevUnits =>
      prevUnits.map(unit => (unit.unit_id === unitId ? { ...unit, is_lock_assigned: true } : unit)),
    );

    // Clear the existing timeout if any
    if (assignLockTimeout) {
      clearTimeout(assignLockTimeout);
    }

    // Set a new timeout to delay the API call by 10 seconds
    const timeoutId = setTimeout(() => {
      async (lockId: number, unitId: number) => {
        try {
          await assignLock({
            assignLock: {
              legacyLockId: lockId,
              legacyPropertyId: unitId,
            },
          }).unwrap();
        } catch (error) {
          logger.error(error);

          setLocksData(prevLocks =>
            prevLocks.map(lock =>
              lock.lock_legacy_id === lockId ? { ...lock, unit_id: null, unit_name: null } : lock,
            ),
          );

          setAllUnits(prevUnits =>
            prevUnits.map(unit =>
              unit.unit_id === unitId ? { ...unit, is_lock_assigned: false } : unit,
            ),
          );
        }
      };
    }, 10000); // 10 seconds delay

    setAssignLockTimeout(timeoutId);
  };

  const handleUndo = (lockId: number, unitId: number) => {
    setLocksData(prevLocks =>
      prevLocks.map(lock =>
        lock.lock_legacy_id === lockId ? { ...lock, unit_id: null, unit_name: null } : lock,
      ),
    );

    // Disable the assigned unit in the units list
    setAllUnits(prevUnits =>
      prevUnits.map(unit =>
        unit.unit_id === unitId ? { ...unit, is_lock_assigned: false } : unit,
      ),
    );

    // Clear the timeout to prevent the API call
    if (assignLockTimeout) {
      clearTimeout(assignLockTimeout);
      setAssignLockTimeout(null); // Clear the reference to the timeout
    }
  };

  const undoAction = (lockId: number, unitId: number) => (
    <Button
      color='secondary'
      size='small'
      sx={{ color: 'white' }}
      onClick={() => handleUndo(lockId, unitId)}
    >
      {t('snackbar_undo_button')}
    </Button>
  );

  const renderAccountInfo = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <TableCell title={row.provider_account_name} description={row.provider_account_user_email} />
    );
  };

  const renderLockName = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return <TableCell title={row.lock_friendly_name} description={row.lock_legacy_id} />;
  };

  const renderPropertyName = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    if (locksBeingUnassigned.includes(row.lock_legacy_id)) {
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <CircularProgress size={24} />
        </Box>
      );
    }

    if (row.unit_id) {
      return <TableCell title={`${row.unit_id} - ${row.unit_name}`} />;
    }
    return (
      <LockAssignPropertyDropdown
        unitList={allUnits}
        onLoadMore={loadMoreUnits}
        hasMore={units.total_count > allUnits.length}
        isLoading={isUnitsLoading}
        onAssign={(unitId, unitName) => {
          if (row.lock_legacy_id) {
            handleAssignLock(row.lock_legacy_id, unitId, unitName);
          }
        }}
      />
    );
  };

  const lastActivityRenderer = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    if (row.last_synced_at === null) return '';
    return <Text.BodySmall>{formatRelativeToTimeZone(row.last_synced_at, 'UTC')}</Text.BodySmall>;
  };

  const renderConnectivity = ({ row }: GridRenderCellParams<unknown, DeviceLockViewModel>) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {row.is_online ? (
          <CloudDoneOutlinedIcon sx={{ color: 'rgb(46, 125, 50, 1)', marginRight: 1 }} />
        ) : (
          <CloudOffOutlinedIcon sx={{ color: 'rgba(211, 47, 47, 1)', marginRight: 1 }} />
        )}
        <TableCell
          title={row.is_online ? 'Online' : 'Offline'}
          sx={{
            color: row.is_online ? 'rgb(46, 125, 50, 1)' : 'rgba(211, 47, 47, 1)',
            marginRight: 1,
          }}
        />
      </Box>
    );
  };

  const columnsDef: GridColDef<DeviceLockViewModel>[] = [
    {
      field: 'provider_account_name',
      headerName: 'Account',
      flex: 1,
      renderCell: renderAccountInfo,
    },
    {
      field: 'lock_friendly_name',
      headerName: 'Lock',
      flex: 1,
      renderCell: renderLockName,
    },
    {
      field: 'unit_id',
      headerName: 'Unit',
      flex: 1,
      renderCell: renderPropertyName,
    },
    {
      field: 'is_online',
      headerName: 'Connectivity',
      flex: 1,
      renderCell: renderConnectivity,
    },
    {
      field: 'last_synced_at',
      headerName: 'Last Synced',
      flex: 1,
      renderCell: lastActivityRenderer,
    },
  ];

  // Add loaded units to the current unit list
  useEffect(() => {
    if (units.data) {
      setAllUnits(prevUnits => [...prevUnits, ...units.data]);
    }
  }, [units]);

  // Cleanup timeout when component unmounts
  useEffect(() => {
    return () => {
      if (assignLockTimeout) {
        clearTimeout(assignLockTimeout);
      }
    };
  }, [assignLockTimeout]);

  const handleSelectionChange = (newSelection: number[]) => {
    setSelectedRows(newSelection); // Update selected rows
  };

  const handleUnassignLocks = () => {
    setDialogOpen(true);
  };

  const handleCancelUnassign = () => {
    setDialogOpen(false); // Close dialog on cancel
  };

  const handleConfirmUnassign = async () => {
    setLocksBeingUnassigned(selectedRows); // Start loading state
    try {
      await Promise.all(
        selectedRows.map(async row => {
          const lockToUnassign = locksData.find(lock => lock.lock_legacy_id === row);

          if (lockToUnassign) {
            const { unit_id: unitId, lock_legacy_id: lockId } = lockToUnassign;

            try {
              await unassignLock({
                unassignLock: { legacyLockId: lockId },
              }).unwrap();

              setLocksData(prevLocks =>
                prevLocks.map(lock =>
                  lock.lock_legacy_id === lockId
                    ? { ...lock, unit_id: null, unit_name: null }
                    : lock,
                ),
              );

              setAllUnits(prevUnits =>
                prevUnits.map(unit =>
                  unit.unit_id === unitId ? { ...unit, is_lock_assigned: false } : unit,
                ),
              );
            } catch (error) {
              logger.error(error);
            }
          }
        }),
      );

      if (selectedRows.length === 1) {
        snackbar(t('single_unassign_snackbar_success_text'));
      } else {
        snackbar(`${selectedRows.length} ${t('multiple_unassign_snackbar_success_text')}`);
      }

      setSelectedRows([]);
    } finally {
      setLocksBeingUnassigned([]); // End loading state
      setDialogOpen(false);
    }
  };

  // Update the toolbarActions
  const toolbarActions = (
    <>
      {menu}
      <Button
        size='medium'
        variant='contained'
        color='primary'
        onClick={handleUnassignLocks}
        sx={{ textTransform: 'none' }}
        disabled={selectedRows.length <= 0}
      >
        {t('unassign_locks_button')} ({selectedRows.length})
      </Button>

      <Button
        size='medium'
        variant='outlined'
        startIcon={<SyncIcon />}
        sx={{ ml: 2, textTransform: 'none' }}
      >
        {t('sync_now_button')}
      </Button>
    </>
  );

  return (
    <>
      <LocksTitleBar handleReloadOnChange={refetch} />

      <PaginatedTable
        enableToolbar
        title={toolbarActions}
        rows={locksData} // Use locally stored locksData for immediate UI updates
        rowCount={locks.total_count}
        loading={isLocksLoading || isLocksFetching || isLocksLoading}
        onFetch={handleLocksFetch}
        columns={columnsDef}
        getRowId={row => row.lock_legacy_id}
        sortingMode='server'
        checkboxSelection
        selectionModel={selectedRows}
        onSelectionModelChange={handleSelectionChange} // Add this to track row selection
      />

      <UnassignUnitDialog
        open={isDialogOpen}
        onCancel={handleCancelUnassign}
        onUnassign={handleConfirmUnassign}
        loading={locksBeingUnassigned.length > 0}
      />
    </>
  );
};

export default LocksTable;
