import {
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListSubheader,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import React, { useRef, useState } from 'react';
import { UnitViewModel } from 'services/novaApi';
import LocksAssignPropertyDropDownSearch from './LocksAssignPropertyDropDownSearch';

export interface LockAssignPropertyDropdownProps {
  unitList?: UnitViewModel[];
  onLoadMore?: () => void; // Function to load more units
  hasMore?: boolean; // Flag to determine if more units are available to load
  isLoading?: boolean; // Flag to indicate if loading is in progress
  onAssign?: (unitId: number, unitName: string) => void; // Callback when a unit is selected
}

export default function LockAssignPropertyDropdown({
  unitList = [],
  onLoadMore,
  hasMore = true,
  isLoading = false,
  onAssign,
}: LockAssignPropertyDropdownProps) {
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown to monitor scroll
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleListItemClick = (index: number, unitId: number, unitName: string) => {
    setSelectedIndex(index);
    onAssign?.(unitId, unitName); // Trigger onAssign when an item is selected
  };

  // Filter the dropdown unitList based on search input
  const filteredOptions = unitList.filter((unit: UnitViewModel) =>
    unit.unit_name?.toLowerCase().includes(searchValue.toLowerCase()),
  );

  // Infinite Scroll - Trigger load more when user reaches the bottom
  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollTop + clientHeight >= scrollHeight && hasMore && onLoadMore) {
      onLoadMore(); // Trigger the load more function passed from parent
    }
  };

  const formControlStyle = {
    marginBottom: '10px',
    width: '100%',
    marginLeft: 0,
  };

  const selectStyle = {
    height: '40px', // Consistent height
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'start',
      padding: '0 14px',
    },
  };

  const paperPropsStyle = {
    maxHeight: 400,
    width: '50%', // Adjust as per requirement
  };

  const listItemButtonStyle = {
    height: '40px', // Consistent height for list items
  };

  return (
    <FormControl fullWidth variant='outlined' sx={formControlStyle}>
      <InputLabel
        sx={{
          '&.MuiInputLabel-outlined': {
            transform: 'translate(14px, 10px) scale(1)',
          },
          '&.Mui-focused': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        }}
        id='unit-select-label'
      >
        {t('assign_unit')}
      </InputLabel>
      <Select
        labelId='unit-select-label'
        label={t('assign_unit')}
        onOpen={() => inputRef.current?.focus()} // Automatically focuses the search input when dropdown is opened
        sx={selectStyle}
        MenuProps={{
          PaperProps: {
            sx: paperPropsStyle,
            ref: dropdownRef,
            onScroll: handleScroll, // Track the scroll event
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <ListSubheader sx={{ padding: '8px' }}>
          <LocksAssignPropertyDropDownSearch
            value={searchValue}
            onChange={handleSearchChange}
            fullWidth
          />
        </ListSubheader>

        {!isLoading ? (
          <List>
            {filteredOptions.map((option, index) => (
              <Tooltip
                key={index}
                title={option.is_lock_assigned ? t('disabled_unit_tooltip') : ''}
                placement='bottom'
              >
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={() => handleListItemClick(index, option.unit_id, option.unit_name)}
                  sx={listItemButtonStyle}
                  disabled={option.is_lock_assigned}
                >
                  {option.unit_name}
                </ListItemButton>
              </Tooltip>
            ))}
          </List>
        ) : (
          <ListItemButton disabled>
            <CircularProgress size={24} />
          </ListItemButton>
        )}

        {hasMore && !isLoading && (
          <Typography variant='body2' noWrap sx={{ width: '100%', textAlign: 'center' }}>
            {t('loading_more')}
          </Typography>
        )}
      </Select>
    </FormControl>
  );
}
