import { Alert, Box, Typography } from '@mui/material';
import {
  LogDataType,
  SendGridDeliveredStatuses,
  SendGridErrorStatuses,
  TwilioDeliveredStatuses,
  TwilioErrorStatuses,
} from '@operto/delivery-logs-shared';
import { AddCard } from '@operto/ui-library';
import { FormContainer } from 'Common/FormContainer';
import { Buffer } from 'buffer';
import DOMPurify from 'dompurify';
import { utcToTimeZone } from 'helper/date';
import { toTitleCase } from 'helper/helper';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

interface DeliveryLogsDetailsProps {
  log: LogDataType;
  handleClose: () => void;
}

const DeliveryLogsDetails = ({ log, handleClose }: DeliveryLogsDetailsProps) => {
  const { t } = useTranslation();

  const {
    channelType,
    guestName,
    recipient,
    scheduledAt,
    status,
    templateName,
    timezone,
    unitName,
    messageBody,
    deliveredAt,
  } = log.row;

  const processedChannelType =
    channelType === 'email' ? toTitleCase(channelType) : channelType.toUpperCase();

  const isDelivered =
    SendGridDeliveredStatuses.includes(status) || TwilioDeliveredStatuses.includes(status);

  const isError = SendGridErrorStatuses.includes(status) || TwilioErrorStatuses.includes(status);

  const formattedScheduledAt = scheduledAt
    ? utcToTimeZone(scheduledAt, timezone, "MMMM dd, yyyy, 'at' h:mm a zzz")
    : '';

  const formattedDeliveredAt = deliveredAt
    ? utcToTimeZone(deliveredAt ?? '', timezone, "MMMM dd, yyyy, 'at' h:mm a zzz")
    : '';

  const getStatusTitle = () => {
    let title = '';
    let subtitle = '';

    if (isDelivered) {
      title = t('successfully_delivered');
      subtitle = t('delivered_on_date', { date: formattedDeliveredAt });
    } else if (isError) {
      title = t('failed_to_deliver_with_status', {
        status: status === 'failed' ? '' : ` (${status})`,
      });
      subtitle = t('failed_to_deliver_on_scheduled_date', { date: formattedScheduledAt });
    } else {
      title = t('failed_to_deliver');
      subtitle = t('failed_to_deliver_on_scheduled_date', { date: formattedScheduledAt });
    }

    return { title, subtitle };
  };

  const processMessageBody = (messageBody: string, channelType: string) => {
    if (channelType === 'email') {
      return Buffer.from(messageBody, 'base64').toString('utf-8');
    }

    return messageBody;
  };

  const renderStatusAlert = () => {
    const { title, subtitle } = getStatusTitle();

    return (
      <>
        <Typography variant='body-lg-600'>{title}</Typography>
        <Typography variant='body2'>{subtitle}</Typography>
      </>
    );
  };

  const renderGuestDetailsBody = () => {
    return (
      <Box display='flex' flexDirection='column' alignItems='flex-start' gap='10px'>
        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='16px'>
          <Typography variant='body-sm-600' sx={{ minWidth: '100px' }}>
            {t('name')}
          </Typography>
          <Typography variant='body2'>{guestName}</Typography>
        </Box>

        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='16px'>
          <Typography variant='body-sm-600' sx={{ minWidth: '100px' }}>
            {t('recipient')}
          </Typography>
          <Typography variant='body2'>{recipient}</Typography>
        </Box>

        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='16px'>
          <Typography variant='body-sm-600' sx={{ minWidth: '100px' }}>
            {`${processedChannelType} ${t('template').toLowerCase()}`}
          </Typography>
          <Typography variant='body2'>{templateName}</Typography>
        </Box>

        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='16px'>
          <Typography variant='body-sm-600' sx={{ minWidth: '100px' }}>
            {t('unit')}
          </Typography>
          <Typography variant='body2'>{unitName}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <FormContainer title={`${processedChannelType} ${t('delivery')}`} onClose={handleClose}>
      <Alert severity={isDelivered ? 'success' : 'error'} sx={alertStyles}>
        {renderStatusAlert()}
      </Alert>
      <AddCard title={<Typography variant='body-lg-600'>{t('guest_details')}</Typography>}>
        {renderGuestDetailsBody()}
      </AddCard>
      <AddCard
        title={
          <Typography variant='body-lg-600'>
            {isDelivered
              ? `${processedChannelType} ${t('delivered').toLowerCase()}`
              : `${processedChannelType} ${t('failed_to_deliver').toLowerCase()}`}
          </Typography>
        }
      >
        {channelType === 'email' ? (
          <Box
            style={{ height: '100%', overflow: 'scroll' }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(processMessageBody(messageBody, channelType)),
            }}
          />
        ) : (
          <Box style={{ whiteSpace: 'pre-wrap' }}>
            {processMessageBody(messageBody, channelType)}
          </Box>
        )}
      </AddCard>
    </FormContainer>
  );
};

const alertStyles = {
  display: 'flex',
  padding: '6px 16px',
  marginBottom: '24px',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: '4px',
};

export default DeliveryLogsDetails;
