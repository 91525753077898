import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

export default function useSnackbar() {
  const dispatch = useAppDispatch();

  const snackbar = (message: string, action?: React.ReactNode | null) =>
    dispatch(
      toggleSnackbar(SnackbarTypes.OPEN, {
        message: message,
        variant: SnackbarVariant.INFO,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        icon: false,
        rootStyles: {
          minWidth: '216px',
          padding: '6px 16px',
          '&>div': { backgroundColor: '#323232', borderRadius: '4px' },
        },
        action,
      }),
    );

  return { snackbar };
}
