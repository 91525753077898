import { Typography } from '@mui/material';
import InfoDialog from 'Common/Dialog/InfoDialog';
import useTranslation from 'hooks/useTranslation';
import React from 'react';

export interface CommunicationsTemplateInfoDialogProps {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
}

const CommunicationsTemplateInfoDialog = ({
  open,
  onClose,
  anchorEl,
}: CommunicationsTemplateInfoDialogProps) => {
  const { t } = useTranslation();

  const paragraphOneText = (
    <Typography variant='body1'>{t('communications_info_dialog_paragraph_one_text')}</Typography>
  );

  const paragraphTwoText = (
    <Typography variant='body1'>{t('communications_info_dialog_paragraph_two_text')}</Typography>
  );

  return (
    <InfoDialog
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      title={t('templates')}
      paragraphOneTitle={t('language_options')}
      paragraphOneText={paragraphOneText}
      paragraphTwoTitle={t('variables')}
      paragraphTwoText={paragraphTwoText}
    />
  );
};

export default CommunicationsTemplateInfoDialog;
