import { DialogContentText, TextField } from '@mui/material';
import { createStripeConnectAccount } from 'api/paymentAPI';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { companySelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

export type StripeAccountIdFormProps = {
  open: boolean;
  accountId: string;
  handleSetAccountId: (accountId: string) => void;
  handleCancel: () => void;
};

export const StripeAccountIdForm = ({
  open,
  accountId,
  handleSetAccountId,
  handleCancel,
}: StripeAccountIdFormProps) => {
  const { t } = useTranslation();
  const company = useSelector(companySelector());
  const companyId: string = company.id.toString();

  const dispatch = useAppDispatch();

  const [isCreateAccountLoading, setIsCreateAccountLoading] = useState<boolean>(false);
  const [accountIdInput, setAccountIdInput] = useState('');

  const createStripeAccount = async () => {
    setIsCreateAccountLoading(true);
    try {
      const createAccountResponse = await createStripeConnectAccount(companyId);
      const stripeAccountId = createAccountResponse.data.data;
      handleSetAccountId(stripeAccountId);
    } catch (error) {
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: t('stripe_account_create_error'),
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  useEffect(() => {
    setAccountIdInput(accountId);
  }, [accountId]);

  return (
    <ConfirmDialog
      title={t('connect_stripe')}
      open={open}
      loading={isCreateAccountLoading}
      onClose={() => handleCancel()}
      onSubmit={() => {
        if (!accountIdInput) {
          createStripeAccount();
        } else {
          handleSetAccountId(accountIdInput);
        }
      }}
    >
      <DialogContentText>{t('connect_stripe_account_description')}</DialogContentText>
      <TextField
        id='text'
        type='text'
        onChange={e => {
          setAccountIdInput(e.target.value);
        }}
        value={accountIdInput}
        style={{ width: '100%', padding: '12px 0px 0px 0px' }}
      />
    </ConfirmDialog>
  );
};

export default StripeAccountIdForm;
