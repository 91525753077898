import { Typography } from '@mui/material';
import { AddCard } from '@operto/ui-library';
import { updateCompanyContext } from 'api/paymentAPI';
import { toDecimalStr } from 'helper/helper';
import { enabledIfVariablesInitialized } from 'helper/reactHookEnabledHelper';
import { useGetCompanyContext } from 'hooks/usePayments';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { FormTextField } from 'ui-library/Components/input/FormTextField';
import { ActionButton, FormHeader } from 'ui-library/Styled/sidebar';
import { userSelector } from 'user/state/userSelectors';

type AdminSettingsForm = {
  transactionFee: string;
  monthlyFeePerUnit: string;
};

export interface AdminSettingsContainerProps {
  companyId: number;
}

const AdminSettingsContainer = ({ companyId }: AdminSettingsContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleSubmit, control, setValue, getValues, formState, reset } =
    useForm<AdminSettingsForm>({
      mode: 'all',
    });
  const { accessToken } = useAppSelector(userSelector());

  const { data: companyContext, refetch } = useGetCompanyContext(
    {
      params: { companyId: companyId, withSession: true },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    enabledIfVariablesInitialized(accessToken, companyId),
  );

  const handleOnBlur = (field: keyof AdminSettingsForm) => {
    const values = getValues();
    if (values[field]) {
      const value = toDecimalStr(values[field]);
      setValue(field, value);
    }
  };
  const onFormValid = async () => {
    let message = {
      message: t('upsell_fees_update_successfully'),
      variant: SnackbarVariant.SUCCESS,
    };

    try {
      const updateData: { [key in keyof AdminSettingsForm]: number } = {
        monthlyFeePerUnit: +getValues().monthlyFeePerUnit,
        transactionFee: +getValues().transactionFee,
      };
      await updateCompanyContext(companyId, updateData);
      refetch();
    } catch (error) {
      logger.error(error);
      message = {
        message: t('upsell_fees_update_failure'),
        variant: SnackbarVariant.ERROR,
      };
    }

    dispatch(toggleSnackbar(SnackbarTypes.OPEN, message));
  };

  const isSaveDisabled = !formState.isDirty || !formState.isValid;

  useEffect(() => {
    if (companyContext?.data) {
      reset({
        monthlyFeePerUnit: toDecimalStr(companyContext.data.monthlyFeePerUnit),
        transactionFee: toDecimalStr(companyContext.data.transactionFee),
      });
    }
  }, [companyContext, reset]);

  return (
    <AddCard
      title='Admin Settings'
      footerChildren={
        <ActionButton
          disabled={isSaveDisabled}
          testId='company-save-button'
          variant='contained'
          color='primary'
          onClick={handleSubmit(onFormValid)}
        >
          {t('save')}
        </ActionButton>
      }
    >
      <FormHeader align='left'>{t('upsell_settings')}</FormHeader>
      <Typography variant='body2'>{t('transaction_fee_is_negotiated')}</Typography>
      <FormTextField
        rules={{
          required: {
            value: true,
            message: '',
          },
          validate: (value: string) => {
            const numVal = +value;
            return (
              (numVal >= 0 && numVal <= 100) ||
              t('transaction_fee_validation_error', { min: 0, max: 100 })
            );
          },
        }}
        field='transactionFee'
        label={t('transaction_fee')}
        control={control}
        data-testid='company-transaction-fee-input'
        required={true}
        type='number'
        inputMode='decimal'
        placeholder={t('transaction_fee_placeholder')}
        onBlur={() => handleOnBlur('transactionFee')}
        inputProps={{
          step: 0.1,
          max: 100,
          min: 0,
        }}
      />
      <FormTextField
        field='monthlyFeePerUnit'
        label={t('monthly_upsell_fee_per_unit')}
        control={control}
        type='number'
        inputMode='decimal'
        onBlur={() => handleOnBlur('monthlyFeePerUnit')}
        inputProps={{
          step: 0.1,
        }}
      />
    </AddCard>
  );
};

export default AdminSettingsContainer;
