import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { AddCard } from '@operto/ui-library';
import { useGetCompanyContext } from 'hooks/usePayments';
import useTranslation from 'hooks/useTranslation';
import React, { ChangeEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { selectOperatorCompanyId } from 'redux/selectors/selectOperatorCompany';
import { userSelector } from 'user/state/userSelectors';
import renderTextField from '../TabsComponents/TextInput';

interface PricingProps {
  price: number;
  taxRate: number;
  confirmationMethod: string;
  paymentType: string;
  quantityStepperEnabled: boolean;
  maxQuantity: number;
  quantityName: string;
  setPrice: (price: number) => void;
  setTaxRate: (taxRate: number) => void;
  setConfirmationMethod: (confirmationMethod: string) => void;
  setPaymentType: (paymentType: string) => void;
  setQuantityStepperEnabled: (quantityStepperEnabled: boolean) => void;
  setMaxQuantity: (maxQuantity: number) => void;
  setQuantityName: (quantityName: string) => void;
}

export const SingleItemPricing = ({
  price,
  taxRate,
  confirmationMethod,
  paymentType,
  quantityStepperEnabled,
  maxQuantity,
  quantityName,
  setPrice,
  setTaxRate,
  setConfirmationMethod,
  setPaymentType,
  setQuantityStepperEnabled,
  setMaxQuantity,
  setQuantityName,
}: PricingProps) => {
  const maxLength = 30;
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuantityName(event.target.value);
  };
  const { t } = useTranslation();
  const formatNumber = (input: string) => {
    const decimalValue = parseFloat(input);
    return isNaN(decimalValue) ? '0' : decimalValue.toFixed(2);
  };
  const priceInputRef = React.useRef<HTMLInputElement>();
  const taxInputRef = React.useRef<HTMLInputElement>();
  const { accessToken } = useAppSelector(userSelector());
  const operatorCompanyId = useSelector(selectOperatorCompanyId);
  const { data: companyContext } = useGetCompanyContext({
    params: { companyId: operatorCompanyId, withSession: true },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const priceTaxDescription = useMemo(() => {
    const content = companyContext?.data;
    let output = t(
      'as_the_merchant_of_record_you_are_responsible_for_collecting_and_remmitting_taxes',
    );
    if (content?.transactionFee) {
      output +=
        ' ' +
        t('ensure_the_price_accounts_for_transaction_fee', {
          transactionFee: content.transactionFee,
        });
    }
    return output;
  }, [companyContext, t]);

  return (
    <>
      <AddCard
        title={
          <>
            <Box display='flex' flexDirection='row' justifyContent='flex-start'>
              <Radio
                checked={paymentType === 'single'}
                onClick={() => setPaymentType('single')}
                data-testid='single-item-chkbox'
              />
              <Box display='flex' flexDirection='column' justifyContent='space-between'>
                <Typography variant='subtitle1'>{t('single_item_fixed_price')}</Typography>
                <Typography variant='body2'>{t('offer_one_item_at_a_set_price')}</Typography>
              </Box>
            </Box>
          </>
        }
      >
        {paymentType === 'single' && (
          <Box display='flex' flexDirection='column' justifyContent='space-between'>
            <Typography variant='subtitle1'>{t('price_and_tax_rate')}</Typography>
            <Typography sx={{ paddingY: '10px' }} variant='body2'>
              {priceTaxDescription}
            </Typography>

            <TextField
              label={t('price')}
              variant='outlined'
              type='number'
              fullWidth
              value={price ? price : ''}
              required={true}
              onChange={event => {
                const value = formatNumber(event.target.value);
                setPrice(+value);
              }}
              onBlur={() => {
                priceInputRef.current.value = '' + price;
              }}
              InputProps={{
                startAdornment: <Typography>$</Typography>,
                endAdornment: <Typography>USD</Typography>,
                inputRef: priceInputRef,
              }}
              data-testid='price-input'
            />
            <TextField
              label={t('tax_rate')}
              variant='outlined'
              type='number'
              fullWidth
              value={taxRate ? taxRate : ''}
              onChange={event => {
                const value = formatNumber(event.target.value);
                setTaxRate(+value);
              }}
              onBlur={() => {
                taxInputRef.current.value = '' + taxRate;
              }}
              InputProps={{
                endAdornment: <Typography>%</Typography>,
                inputRef: taxInputRef,
              }}
              data-testid='tax-input'
            />

            <FormControlLabel
              control={
                <Checkbox
                  name='quantity_stepper_enabled'
                  color='primary'
                  inputProps={{ maxLength: 30 }}
                  checked={quantityStepperEnabled}
                  onChange={() => setQuantityStepperEnabled(!quantityStepperEnabled)}
                />
              }
              label={
                <Box display='flex' flexDirection='column' justifyContent='space-between'>
                  <Typography variant='subtitle1'>{t('quantity_stepper_ui')}</Typography>
                  <Typography variant='body2'>
                    {t(
                      'allows_guests_to_select_quantity_of_items_that_get_multiplied_by_unit_price_in_orders',
                    )}
                  </Typography>
                </Box>
              }
              sx={{ paddingY: '10px' }}
            />

            {quantityStepperEnabled && (
              <>
                {renderTextField(
                  t('item_name'),
                  t('item_name'),
                  quantityName,
                  handleNameChange,
                  maxLength,
                  true,
                  1,
                  false,
                )}

                <TextField
                  label={t('quantity_limit')}
                  variant='outlined'
                  fullWidth
                  value={maxQuantity ? maxQuantity : ''}
                  required={true}
                  onChange={event => setMaxQuantity(Number(event.target.value))}
                />
              </>
            )}

            <Typography sx={{ paddingTop: '10px' }} variant='subtitle1'>
              {t('service_button')}
            </Typography>

            <Typography sx={{ paddingTop: '10px' }} variant='body2'>
              {t('how_guests_will_confirm_this_offer')}
            </Typography>

            <FormControl fullWidth margin='normal'>
              <RadioGroup
                value={confirmationMethod}
                onChange={event => setConfirmationMethod(event.target.value)}
              >
                <FormControlLabel
                  sx={{ paddingTop: '10px' }}
                  value='request'
                  control={<Radio />}
                  label={t('request_manual_approval')}
                />
                <FormControlLabel
                  sx={{ paddingTop: '10px' }}
                  value='instant'
                  control={<Radio />}
                  label={t('buy_auto_approval')}
                />
                <FormControlLabel
                  sx={{ paddingTop: '10px' }}
                  value='cash'
                  control={<Radio />}
                  label={t('pay_with_cash_disables_stripe')}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
      </AddCard>
    </>
  );
};
