import { CloseOutlined, KeyOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import {
  TitlebarBreadCrumb,
  TitlebarBreadCrumbChild,
  TitlebarRoot,
  TitlebarTitleIcon,
} from '@operto/ui';
import { companySelector } from 'company/state/companySelectors';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useGetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusQuery } from 'services/novaApi';
import LocksContentPopup from './LocksContentPopup';

const addButtonStyle = {
  textTransform: 'none',
  borderRadius: 8,
  margin: '8px 4px',
  fontWeight: 700,
  letterSpacing: '0.46px',
  alignItems: 'center',
  gap: '8px',
};

const handleClosePopupStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  color: '#000',
};

interface LocksTitleBarProps {
  handleReloadOnChange: () => void; // Adjust type to accept parameters
}

export const LocksTitleBar = ({ handleReloadOnChange: reloadOnChange }: LocksTitleBarProps) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { snackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const company = useAppSelector(companySelector());
  const companyId = company?.id;
  const { data: onboardingStatusData } =
    useGetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusQuery({
      legacyCompanyId: companyId,
    });
  const isOnboarded = onboardingStatusData?.is_onboarded;

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = useCallback(
    (reload: boolean, userEmail?: string) => {
      setOpenPopup(false);
      if (reload) {
        reloadOnChange();
        snackbar(`Yale Access account added: ${userEmail ?? ''}`);
      }
    },
    [reloadOnChange, snackbar],
  );

  const crumbs: TitlebarBreadCrumbChild[] = [
    {
      name: t('home'),
      href: '/',
    },
    {
      name: 'Locks',
    },
  ];

  return (
    <>
      <TitlebarRoot>
        <TitlebarTitleIcon>
          <KeyOutlined />
        </TitlebarTitleIcon>

        <TitlebarBreadCrumb crumbs={crumbs} onClick={crumb => navigate(crumb.href)} />

        {isOnboarded && (
          <Button sx={addButtonStyle} color='primary' variant='contained' onClick={handleOpenPopup}>
            <AddIcon />
            Account
          </Button>
        )}
      </TitlebarRoot>
      <Dialog open={openPopup} onClose={() => handleClosePopup(false)} maxWidth='md' fullWidth>
        <DialogTitle>
          <IconButton onClick={() => handleClosePopup(false)} sx={handleClosePopupStyle}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LocksContentPopup companyId={companyId} onClose={handleClosePopup} />
        </DialogContent>
      </Dialog>
    </>
  );
};
