import { Box } from '@mui/material';
import { getCompanyInformation } from 'company/state/companyAction';
import { companySelector } from 'company/state/companySelectors';
import { logger } from 'lib/logger';
import { LockFilterType } from 'lock/lockType';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdQuery,
  useGetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusQuery,
  usePostAccessCompatibilityOnboardingGenerateLinkMutation,
  usePostAccessCompatibilityOnboardSeamAccountMutation,
} from 'services/novaApi';
import LoadingContainer from 'ui-library/Components/misc/LoadingContainer';
import LocksTable from './LocksTable';

// Extracted styles for shared Box styling
const boxCenteredStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: '20px',
};

// Extracted styles for iframe dimensions
const iframeStyle = {
  borderRadius: '28px',
  border: 'none',
  minHeight: '600px',
  height: '702.533px',
  width: '400px',
};

const LocksPage = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(companySelector());
  const companyId = company?.id;
  const [filterType] = useState(LockFilterType.ALL_LOCKS);
  const [connectWebviewUrl, setConnectWebviewUrl] = useState<string | null>(null);
  const [connectWebviewId, setConnectWebviewId] = useState<string | null>(null);
  const [connectedAccountId, setConnectedAccountId] = useState<string | null>(null);
  const [triggerGenerateLink, { data, isSuccess }] =
    usePostAccessCompatibilityOnboardingGenerateLinkMutation();
  const [isSeamAccount, setIsSeamAccount] = useState<boolean>(false);
  const [loadingTimeout, setLoadingTimeout] = useState<boolean>(false);

  const {
    data: onboardingStatusData,
    isSuccess: isOnboardingStatusSuccess,
    isLoading: isOnboardingStatusLoading,
  } = useGetAccessCompatibilityOnboardingByLegacyCompanyIdSeamOnboardingStatusQuery({
    legacyCompanyId: companyId,
  });

  const [triggerOnboardSeamAccount] = usePostAccessCompatibilityOnboardSeamAccountMutation();

  const { data: connectedAccount } =
    useGetAccessCompatibilityOnboardingByLegacyCompanyIdConnectWebviewAndConnectWebviewIdConnectedAccountIdQuery(
      { legacyCompanyId: companyId, connectWebviewId: connectWebviewId },
      { skip: !companyId || !connectWebviewId }, // Fetch only when both params are available
    );

  useEffect(() => {
    dispatch(getCompanyInformation());
  }, [dispatch]);

  useEffect(() => {
    if (isOnboardingStatusSuccess && onboardingStatusData) {
      setIsSeamAccount(onboardingStatusData.is_onboarded);

      if (!onboardingStatusData.is_onboarded) {
        triggerGenerateLink({ redirectUrl: '/locks' });
      }
    }
  }, [isOnboardingStatusSuccess, onboardingStatusData, triggerGenerateLink]);

  useEffect(() => {
    if (isSuccess && data?.connect_view_url) {
      setConnectWebviewUrl(data.connect_view_url);
    }
  }, [isSuccess, data?.connect_view_url]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.event?.event_type === 'connect_webview.login_succeeded') {
        setConnectWebviewId(event.data?.event?.connect_webview_id);
      }
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, [connectedAccount]);

  useEffect(() => {
    if (connectedAccount) {
      setConnectedAccountId(connectedAccount.connected_account_id);
    }
  }, [connectedAccount]);

  useEffect(() => {
    let timerId: string | number | NodeJS.Timeout = null;

    const onboardSeamAccount = async () => {
      if (connectedAccountId && companyId && !isSeamAccount) {
        setLoadingTimeout(true);

        try {
          await triggerOnboardSeamAccount({
            importSeamAccount: {
              legacyCompanyId: companyId,
              seamConnectedAccountId: connectedAccountId,
            },
          }).unwrap();

          setIsSeamAccount(true);
          setConnectWebviewUrl(null); // Hide the iframe on success
        } catch (error) {
          logger.error('Failed to onboard Seam account', error);
        } finally {
          timerId = setTimeout(() => {
            setLoadingTimeout(false); // Prevent race condition while waiting for locks to load onto nova db
          }, 2000);
        }
      }
    };

    onboardSeamAccount();

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [companyId, triggerOnboardSeamAccount, connectedAccountId, isSeamAccount]);

  const isLoading =
    isOnboardingStatusLoading || (!connectWebviewUrl && !isSeamAccount) || loadingTimeout;

  // Early return for loading state
  if (isLoading) {
    return (
      <Box sx={boxCenteredStyle}>
        <LoadingContainer loading={true} />
      </Box>
    );
  }

  // Early return for Connect Webview
  if (!onboardingStatusData.is_onboarded && connectWebviewUrl) {
    return (
      <Box sx={boxCenteredStyle}>
        <iframe
          style={iframeStyle}
          allow='clipboard-write'
          src={connectWebviewUrl}
          title='Connect Webview'
        />
      </Box>
    );
  }

  if (isSeamAccount) {
    return <LocksTable filterType={filterType} />;
  }
};

export default LocksPage;
